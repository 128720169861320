












































import {
  BNavItemDropdown,
  BDropdownItem,
  BDropdownDivider,
  BAvatar,
} from "bootstrap-vue";
import { avatarText } from "@core/utils/filter";
import ability from "@/libs/acl/ability";
import events from "@/resources/systems-modules-events/events.json";
import { Component, Vue } from "vue-property-decorator";
import { useAuth, useCache } from "@/services";

@Component({
  name: "UserDropdown",
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
  },
})
export default class UserDropdown extends Vue {
  // Data
  userData = JSON.parse(
    localStorage.getItem(useAuth.serviceConfig.storageUserDataName) || "{}"
  );
  avatarText = avatarText;
  ability = ability;
  events = events;
  onLogout = false;
  urlLogoutEmplacamento = process.env.VUE_APP_BASE_URL_EMP + "/Account/LogOn";

  // Computeds
  get url(): string {
    return this.onLogout ? this.urlLogoutEmplacamento : "";
  }

  fullLogout() {
    this.onLogout = true;
  }

  finishLogoutEmplacamento() {
    if (this.onLogout) {
      this.onLogout = false;
      this.logout();
    }
  }

  async logout() {
    //OBS: Apagar cache backend deve ser realizado apenas em userdropdown, nao faz sentido realizar requisicao apagar cache com token invalido (401).
    await useCache.requestDelete();
    await useAuth.logout(
      "",
      process.env.VUE_APP_LOGOUT_URL ? process.env.VUE_APP_LOGOUT_URL : ""
    );
  }
}
